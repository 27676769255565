/* eslint-disable @typescript-eslint/no-use-before-define */
import createReducer from 'core/lib/createReducer';
import * as OvationsApi from 'core/ovations-api';
import { Program, UpdateTranslationRequest } from 'core/ovations-api/definitions';
import { replaceIndex } from 'core/util/arrays';
import { keyBy, map as _map, orderBy, sortBy, values } from 'lodash';
import ExtraArg from 'redux-modules/definitions/ExtraArg';
import ProgramState from 'redux-modules/definitions/ProgramState';
import S from 'redux-modules/definitions/RootState';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createSelector } from 'reselect';

export const initialState: ProgramState = {
    map: {},
    searchText: '',
    isFetching: false,
};

export const emptyProgram: Program = {
    createDate: '',
    id: '',
    number: 0,
    name: '',
    portalUrl: null,
    programAppeasements: [],
    isAppeasementApprovalRequired: true,
    autoDisqualifyIncompleteClaims: false,
    incompleteDaysLimit: 0,
    languages: [],
    enabledCountries: [],
    promotionGroups: [],
};

const { reducer, update } = createReducer<ProgramState>('program/UPDATE', initialState);
export const programReducer = reducer;

export const actions = {
    update,

    fetchAll: (clientId: string): ThunkAction<Promise<void>, S, ExtraArg, Action> => {
        return async (dispatch, getState, { clientContextManager }) => {
            dispatch(clientContextManager.action(clientId, update({ isFetching: true })));
            try {
                const list = await OvationsApi.Program.fetchAll(clientId);
                const map = keyBy(list, 'id');
                dispatch(clientContextManager.action(clientId, update({ map, isFetching: false })));
            } catch (e) {
                dispatch(clientContextManager.action(clientId, update({ isFetching: false })));
                throw e;
            }
        };
    },

    createNewProgram: (clientId: string, program: Program): ThunkAction<Promise<string>, S, ExtraArg, Action> => {
        return async (dispatch, getState, { clientContextManager }) => {
            const programId = await OvationsApi.Program.create(clientId, program);
            dispatch(clientContextManager.action(clientId, update({ searchText: '' })));
            return programId;
        };
    },

    updateProgram: (clientId: string, program: Program): ThunkAction<void, S, ExtraArg, Action> => {
        return async (dispatch) => {
            await OvationsApi.Program.update(clientId, program);
            dispatch(actions.upsertProgram(clientId, program));
        };
    },

    addPromotionGroup: (
        clientId: string,
        program: Program,
        newPromotionGroup: OvationsApi.Types.PromotionGroup,
    ): ThunkAction<Promise<OvationsApi.Types.PromotionGroup>, S, ExtraArg, Action> => {
        return async (dispatch) => {
            const newId = await OvationsApi.Program.addPromotionGroup(clientId, program.id, newPromotionGroup);
            newPromotionGroup.id = newId;
            const updatedProgram: Program = {
                ...program,
                promotionGroups: orderPromotionGroups([...program.promotionGroups, newPromotionGroup]),
            };
            dispatch(actions.upsertProgram(clientId, updatedProgram));
            return newPromotionGroup;
        };
    },

    updatePromotionGroup: (
        clientId: string,
        program: Program,
        updatedPromotionGroup: OvationsApi.Types.PromotionGroup,
    ): ThunkAction<void, S, ExtraArg, Action> => {
        return async (dispatch) => {
            await OvationsApi.Program.updatePromotionGroup(clientId, program.id, updatedPromotionGroup);

            const updatedPromotionGroups = _map(
                program.promotionGroups,
                (promotionGroup: OvationsApi.Types.PromotionGroup) => {
                    return promotionGroup.id === updatedPromotionGroup.id ? updatedPromotionGroup : promotionGroup;
                },
            );

            const updatedProgram = {
                ...program,
                promotionGroups: orderPromotionGroups(updatedPromotionGroups),
            };
            dispatch(actions.upsertProgram(clientId, updatedProgram));
        };
    },

    deletePromotionGroup: (
        clientId: string,
        program: Program,
        promotionGroupId: string,
    ): ThunkAction<void, S, ExtraArg, Action> => {
        return async (dispatch) => {
            await OvationsApi.Program.deletePromotionGroup(clientId, program.id, promotionGroupId);

            const promotionGroups = program.promotionGroups.filter((x) => x.id !== promotionGroupId);

            const updatedProgram: Program = {
                ...program,
                promotionGroups: orderPromotionGroups(promotionGroups),
            };
            dispatch(actions.upsertProgram(clientId, updatedProgram));
        };
    },

    fetchSinglePromotionGroup(
        clientId: string,
        programId: string,
        promotionGroupId: string,
    ): ThunkAction<Promise<void>, S, ExtraArg, Action> {
        return async (dispatch, getState, { clientContextManager }) => {
            const fetchedPromotionGroup = await OvationsApi.Program.fetchSinglePromotionGroup(
                clientId,
                programId,
                promotionGroupId,
            );
            if (!fetchedPromotionGroup) {
                return;
            }
            const ctx = clientContextManager.getContext(getState(), clientId);
            const program = ctx.program.map[programId];
            const updatedPromotionGroups = _map(
                program.promotionGroups,
                (promotionGroup: OvationsApi.Types.PromotionGroup) => {
                    return promotionGroup.id === fetchedPromotionGroup.id ? fetchedPromotionGroup : promotionGroup;
                },
            );

            const updatedProgram = {
                ...program,
                promotionGroups: orderPromotionGroups(updatedPromotionGroups),
            };
            dispatch(actions.upsertProgram(clientId, updatedProgram));
        };
    },

    addProgramLanguage: (
        clientId: string,
        program: Program,
        createProgramLanguageRequest: OvationsApi.Types.CreateProgramLanguageRequest,
    ): ThunkAction<void, S, ExtraArg, Action> => {
        return async (dispatch) => {
            await OvationsApi.Program.addProgramLanguage(clientId, program.id, createProgramLanguageRequest);

            const language: OvationsApi.Types.ProgramLanguage = {
                cultureCode: createProgramLanguageRequest.cultureCode,
                name: createProgramLanguageRequest.name,
                isActive: false,
                lastUpload: null,
            };
            const updatedProgram: Program = {
                ...program,
                languages: [...program.languages, language],
            };
            dispatch(actions.upsertProgram(clientId, updatedProgram));
        };
    },

    activateProgramLanguage: (
        clientId: string,
        program: Program,
        programLanguage: OvationsApi.Types.ProgramLanguage,
    ): ThunkAction<void, S, ExtraArg, Action> => {
        return async (dispatch) => {
            await OvationsApi.Program.activateProgramLanguage(clientId, program.id, programLanguage);
            dispatch(actions.upsertProgram(clientId, program));
        };
    },

    deactivateProgramLanguage: (
        clientId: string,
        program: Program,
        programLanguage: OvationsApi.Types.ProgramLanguage,
    ): ThunkAction<void, S, ExtraArg, Action> => {
        return async (dispatch) => {
            await OvationsApi.Program.deactivateProgramLanguage(clientId, program.id, programLanguage);

            const index = program.languages.findIndex((ps) => ps.cultureCode === programLanguage.cultureCode);
            const updateProgram: Program = {
                ...program,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                languages: replaceIndex(program.languages, index, (ps) => ({ ...ps!, isActive: false })),
            };
            dispatch(actions.upsertProgram(clientId, updateProgram));
        };
    },

    updateProgramLanguages: (
        clientId: string,
        program: Program,
        translations: UpdateTranslationRequest[],
    ): ThunkAction<void, S, ExtraArg, Action> => {
        return async (dispatch) => {
            await OvationsApi.Translation.updateFiles(clientId, program.id, translations);
            const languages = program.languages.map((language) => {
                return translations.some((translation) => translation.cultureCode === language.cultureCode)
                    ? { ...language, lastUpload: new Date().toISOString() }
                    : language;
            });
            const updatedProgram = { ...program, languages };
            dispatch(actions.upsertProgram(clientId, updatedProgram));
        };
    },

    upsertProgram: (clientId: string, program: Program): ThunkAction<void, S, ExtraArg, Action> => {
        return (dispatch, getState, { clientContextManager }) => {
            const ctx = clientContextManager.getContext(getState(), clientId);
            const map = { ...ctx.program.map, [program.id]: program };
            dispatch(clientContextManager.action(clientId, update({ map })));
        };
    },
};

function orderPromotionGroups(promotionGroups: OvationsApi.Types.PromotionGroup[]) {
    return orderBy(promotionGroups, [(x) => x.name.toLowerCase()], ['asc']);
}

const getList = createSelector(
    (state: ProgramState) => state.map,
    (map: ProgramState['map']) => values(map),
);

export const selectors = {
    getList,
    getFilteredList: createSelector(
        [getList, (state) => state.searchText, (state) => state.sortKey, (state) => state.sortDescending],
        (list, searchText, sortKey, sortDescending) => {
            const re = new RegExp(searchText, 'i');
            const filteredRows = list.filter((program: Program) => {
                return re.test(program.name) || re.test(String(program.number));
            });
            let rows = filteredRows;
            if (sortKey) {
                rows = sortBy(rows, sortKey);
            }
            if (sortDescending === true) {
                rows = rows.reverse();
            }
            return rows;
        },
    ),
};
